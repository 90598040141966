import axios from 'axios';
import configData from '@/config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.dataLoaders.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export const retrieveResources = ({
  extractEnvironment,
  customer,
  resourceId,
  userId,
}) => {
  const params = new URLSearchParams();
  params.append('env', extractEnvironment);
  params.append('userId', userId);
  params.append('customer', customer);
  const options = {
    method: 'GET',
    params,
    url: `/resource/${resourceId}/all`,
  };
  return apiClient(options);
};
